
  import axios from 'axios'
  import Vue from 'vue'
  import SliderTabs from '@/components/SliderTabs.vue'
  import { ethers } from 'ethers'
  import { providers } from '@0xsequence/multicall'
  import { delay, equalsInsensitive, toFloat } from '@/utils'
  import { IEcosystem, EcosystemId, ChainId } from '@/ecosystem'
  import { mapActions } from 'vuex'
  import AwaitLock from 'await-lock'
  import {
    BSC_MINTER_ADDRESS,
    MOVR_MINTER_ADDRESS,
    MINTER_ABI,
    PADSWAP_PAIR_ABI,
    ERC20_ABI,
  } from '../constants'
  import VueApexCharts from 'vue-apexcharts'

  import { padAddress as padAddressBSC, vault as vaultBSC } from '../farms_config_bsc.json'
  import { padAddress as padAddressMOVR, vault as vaultMOVR } from '../farms_config_movr.json'
  import { padAddress as padAddressGLMR, vault as vaultGLMR } from '../farms_config_glmr.json'

  export default Vue.extend({
    components: {
      SliderTabs, 
      apexchart: VueApexCharts
    },
    data () {
      return {
        active: <boolean> true,
        syncLock: new AwaitLock(),
        options: <any> {
          stroke: {
            show: false,
            colors: ["#181d26b3"]
          },
          tooltip: <any> {
            enabled: true,
              y: {
                formatter: (value : any, { series, seriesIndex, dataPointIndex, w } : any ) => {
                  // @ts-ignore
                  return '$' + this.biOrMiOrK(value)
                }
              },
              x: {
                format: 'dd MMM yyyy',
              },
          },
          legend: {
            position: 'bottom',
            formatter: (seriesName : string) => {
              // @ts-ignore
              return seriesName
            }

          },
          chart: {
            toolbar: {
              show: false
            },
                colors: ["green", "blue", "purple", "yellow", "orange", "black", "white"]
          },
          plotOptions: {
            pie: {
              donut: {
                size: '50%'
              }
            },
            treemap: {
              distributed: true,
              useFillColorAsStroke: true
            }
          },
          colors: []
        },
        series: [],

        burnOptions: <any> {
          stroke: {
            show: false,
          },
          tooltip: <any> {
            enabled: true,
              y: {
                formatter: (value : any, { series, seriesIndex, dataPointIndex, w } : any ) => {
                  // @ts-ignore
                  return this.biOrMiOrK(value) + ' PAD'
                }
              },
              x: {
                format: 'dd MMM yyyy',
              },
          },
          legend: {
            position: 'bottom',
            formatter: (seriesName : string) => {
              // @ts-ignore
              return seriesName
            }

          },
          chart: {
            toolbar: {
              show: false
            },
          },
          plotOptions: {
            pie: {
              donut: {
                size: '60%'
              }
            },
            treemap: {
              distributed: true,
              useFillColorAsStroke: true
            }
          },
        },
        burnSeries: [],

        inflationOptions: {
      zoom: {
        enabled: true,
        type: 'x',  
        autoScaleYaxis: true
      },
      colors: ["#bfbf1f"],
      tooltip: {
        enabled: true,
          y: {
            formatter: function(value: any, { series, seriesIndex, dataPointIndex, w }: { series: any, seriesIndex: any, dataPointIndex: any, w: any }) {
              return value+'%'
            }
          },
          x: {
            format: 'dd MMM yyyy',
          },
        },
        chart: {
          id: 'vuechart-example'
        },
        xaxis: {
          type: 'datetime',
          tooltip: {
            enabled: false
          },
          labels: {
            show: true,
            style: {
              colors: 'gray',
              fontSize: '12px'
            },
          }
        },
        yaxis: {
          min: 0,
          max: 2.5,
          tickAmount: 5,
          type: 'datetime',
          tooltip: {
            enabled: false
          },
          labels: {
            show: true,
            style: {
              colors: 'gray',
              fontSize: '12px'
            },
          }
        }
      },
      circulatingOptions: {
      colors: ["#77bf1f"],
        tooltip: {
          enabled: true,
            y: {
              formatter: function(value: any, { series, seriesIndex, dataPointIndex, w }: { series: any, seriesIndex: any, dataPointIndex: any, w: any }) {
                return value + 'BI'
              }
            },
            x: {
              format: 'dd MMM yyyy',
            },
        },
        chart: {
          id: 'vuechart-example'
        },
        xaxis: {
          type: 'datetime',
          tooltip: {
            enabled: false,
            theme:'dark',
            style: {
              backgroundColor: 'black'
            }
          },
          labels: {
            show: true,
            style: {
              colors: 'gray',
              fontSize: '12px'
            },
          }
        },
        yaxis: {
          min: 0,
          max: 200,
          tickAmount: 5,
          type: 'datetime',
          tooltip: {
            enabled: false,
            theme:'dark',
            style: {
              backgroundColor: 'black'
            }
          },
          labels: {
            formatter: function(value : any, timestamp: any, opts: any) {
              return value+'BI'
            },
            show: true,
            style: {
              colors: 'gray',
              fontSize: '12px'
            },
          }
        }
      },
      inflation: [{
        name: 'Inflation Rate',
        data: []
      }],
      supply: [{
        name: 'Circulating Supply',
        data: []
      }],


        inflationCalculated: <boolean> false,

        totalBacking: 0,

        // PAD stats
        mcap: 0,
        padSupply: <number> 0,
        padBurned: <number> 0,
        padPrice: <number> 0,
        backingPercentage: <string> '0',
        userPadBalance: <number> 0,

        amount: <number> 0,
        expected: <string> '~0 USD',

        contractAddress: <string> '',
        contractABI: <any> {},

        tokens: <any> [],
        pairs: <any> [],

        userTokenAllowance: <number> 0,

        vaultProcessed: <boolean> false,
        
        animationInterval: <number> 0
      }
    },
    created () {
      setTimeout(async ()=>{
        await this.loadVaultData()
          await this.getVaultLpValue()
          if(this.isMobile()) await this.scrollTo('backing-animation', 120)
          await this.loadVaultAnimation()
          },1)
    },
    async mounted () {
      while (this.active) {
        try {
          await this.sync()
        } catch (e) {
          console.error(e)
        }

        await delay(3000)
      }
    },
    watch: {
      amount() {
        const expectedUSD : number = this.round(( this.amount * this.padPrice * parseFloat(this.backingPercentage)) / 100, 2)
        this.expected = '~' + expectedUSD.toString() + ' USD'
      }
    },
    computed: {
      vault(): any {
        if (this.chainName == 'bsc') {
          return vaultBSC
        }
        else if (this.chainName == 'moonriver') {
          return vaultMOVR
        }
        else {
          return vaultGLMR
        }
      },
      padAddress() : string {
        if (this.chainName == 'bsc') {
          return padAddressBSC
        }
        else if (this.chainName == 'moonriver') {
          return padAddressMOVR
        }
        else {
          return padAddressGLMR
        }
      },
      ecosystemId: {
        get(): EcosystemId {
          return this.$store.state.ecosystemId
        },
        set(val: EcosystemId) {
          this.$store.commit('setEcosystemId', val)
          this.totalBacking = 0
          this.backingPercentage = '0'
          this.mcap = 0
          this.vaultProcessed = false
          setTimeout(async () => {
              await this.loadVaultData()
              setTimeout(async () => {
              await this.getVaultLpValue()
              if(this.isMobile()) await this.scrollTo('backing-animation', 120)
              await this.loadVaultAnimation()
            }, 1000)
          }, 1000)
        }
      },
      address(): string {
        return this.$store.state.address
      },
      multicall(): ethers.providers.Provider {
        return this.$store.getters.multicall
      },
      ecosystem(): IEcosystem {
        return this.$store.getters.ecosystem
      },
      priceModel() : any {
        return this. $store.getters.ecosystem.priceModel
      },
      web3(): ethers.Signer | null {
        return this.$store.state.web3
      },
      chainId(): ChainId {
        return this.$store.getters.ecosystem.chainId
      },
      chainName(): string {
        return this.$store.getters.ecosystem.routeName
      },
      isApproveComplete(): boolean {
        if (this.userTokenAllowance == 0) {
          return false
        }

        return (this.userTokenAllowance >= this.amount)
      },
    },
    beforeRouteLeave (to, from, next) {
      next()
    },
    methods: {
      loadVaultAnimation() {
          clearInterval(this.animationInterval)
          var cnt=document.getElementById("count")
          var water=document.getElementById("water")
          var percent=0
          cnt!.innerHTML = 0 + '%'
          water!.style!.transform='translate(0, 100%)'
       
          this.animationInterval = parseFloat( setInterval(()=>{ 
            percent++
            cnt!.innerHTML = percent + '%'
            var movementDirection = Math.sign( parseFloat(this.backingPercentage) - percent)
            water!.style!.transform='translate(0'+','+(movementDirection * (100-percent) )+'%)'
            if(percent==Math.floor(parseFloat(this.backingPercentage))){
              clearInterval(this.animationInterval)
            }
          }, 50).toString() )
      },
      async sleep(time : any) {
        return new Promise(resolve => setTimeout(resolve, time))
      },
      isMobile() {
        let hasTouchScreen = false
        if ("maxTouchPoints" in navigator) {
            // @ts-ignore
            hasTouchScreen = navigator.maxTouchPoints > 0
        } else if ("msMaxTouchPoints" in navigator) {
            // @ts-ignore
            hasTouchScreen = navigator.msMaxTouchPoints > 0
        } else {
            const mQ = window.matchMedia && matchMedia("(pointer:coarse)")
            if (mQ && mQ.media === "(pointer:coarse)") {
                hasTouchScreen = !!mQ.matches
            } else if ('orientation' in window) {
                hasTouchScreen = true // deprecated, but good fallback
            } else {
                // Only as a last resort, fall back to user agent sniffing
                // @ts-ignore
                const UA = navigator.userAgent
                hasTouchScreen = (
                    /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
                    /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
                )
            }
        }
        return hasTouchScreen
      },
      scrollTo(el : any, offset : any) {
          var element = document.getElementById(el)
          var headerOffset = offset
          var elementPosition = element!.getBoundingClientRect()!.top
          var offsetPosition = elementPosition + window.pageYOffset - headerOffset
          
          window.scrollTo({
              top: offsetPosition,
              behavior: "smooth"
          });  
      },
      
      setMax() {
        this.amount = Math.floor(this.userPadBalance)
      },

      async sync() {
          await this.syncLock.acquireAsync()
          try {
            await this.syncInternal()
          } finally {
            this.syncLock.release()
          }
      },
      async syncInternal() {
        const vault = this.contractAddress
          if (!vault) {
            return
          }
      },
      round(num : any, dec : any) {
        num = Number(num).toFixed(20)
        if(!Number.isFinite(Number(num))) num = '0.0'
        num = Number(num).toFixed(20)
        const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${dec}})?`)
        let [int, decimals] = num.toString().replace(',', '.').split('.')
        if(dec == 0) return int
        const rounded = num.toString().match(regex)[0]
        return rounded
      },
      biOrMiOrK(num : number) : string {
        if(num>=1e9) return this.round(num/1e9, 2) + 'BI'
        else if(num>=1e6) return this.round(num/1e6, 2) + 'M'
        else if (num>=1e3) return this.round(num/1e3, 2) + 'K'
        else if (num>= 1e2) return this.round(num, 2)
        else if (num >= 1) return this.round(num, 4)
        else return this.round(num, 6)
      },
      getBlockExplorerLink() : string {
        let chainExplorerLinks : any = {
          'bsc': 'https://bscscan.com/address/',
          'moonriver': 'https://moonriver.moonscan.io/address/',
          'moonbeam': 'https://moonbeam.moonscan.io/address/'
        }
        let chainExplorerNames : any = {
          'bsc': 'BSCscan',
          'moonriver': 'Moonscan',
          'moonbeam': 'Moonscan'
        }
        let chain = this.$store.getters.ecosystem.routeName
        let explorerLink = chainExplorerLinks[chain]
        let explorerName = chainExplorerNames[chain]
        return '<a href="' + explorerLink + this.contractAddress + '" target="_blank">View on ' + explorerName +'</a>'
      },
      async loadVaultData () {
        this.contractAddress = this.vault.address
        this.contractABI = this.vault.abi
        this.tokens = this.vault.tokens
        this.pairs = this.vault.pairs
        return
      },
      async getVaultLpValue() {

        // TODO: split this abomination into multiple smaller functions

        let totalBackingUSD : number = 0.0
        let vaultData : any = {}

        const blockNumber = await this.multicall.getBlockNumber()

        // Dict for all retrieved data
        let pairs : any = {}
        for (const pairAddress of this.pairs) {
          pairs[pairAddress] = {
            token0Address: "",
            token1Address: "",
            pairReserves: 0,
            token0Name: "",
            token1Name: "",
            token0Decimals: 18,
            token1Decimals: 18,
            pairTotalSupply: 0,
            pairInVault: 0
          }
        }

        // Data from the pair contracts
        let promises = [this.priceModel.syncWithin(blockNumber, 12)]
        for (const pairAddress of this.pairs) {

          const pairContract = new ethers.Contract(pairAddress, PADSWAP_PAIR_ABI, this.multicall)

          const p1 = pairContract.token0().then((res : any) => pairs[pairAddress].token0Address = res)
          const p2 = pairContract.token1().then((res : any) => pairs[pairAddress].token1Address = res)
          const p3 = pairContract.getReserves().then((res : any) => pairs[pairAddress].pairReserves = res)
          const p4 = pairContract.totalSupply().then((res : any) => pairs[pairAddress].pairTotalSupply = res)
          const p5 = pairContract.balanceOf(this.contractAddress).then((res : any) => pairs[pairAddress].pairInVault = res)

          promises.push(p1, p2, p3, p4, p5)
        }
        await Promise.all(promises)

        // Data from individual token contracts
        promises = [this.priceModel.syncWithin(blockNumber, 12)]
        for (const pairAddress of this.pairs) {

          const token0Contract = new ethers.Contract(pairs[pairAddress].token0Address, ERC20_ABI, this.multicall)
          const token1Contract = new ethers.Contract(pairs[pairAddress].token1Address, ERC20_ABI, this.multicall)

          const p1 = token0Contract.name().then((res : any) => pairs[pairAddress].token0Name = res)
          const p2 = token1Contract.name().then((res : any) => pairs[pairAddress].token1Name = res)
          const p3 = token0Contract.decimals().then((res : any) => pairs[pairAddress].token0Decimals = res)
          const p4 = token1Contract.decimals().then((res : any) => pairs[pairAddress].token1Decimals = res)

          promises.push(p1, p2, p3, p4)
        }
        await Promise.all(promises)

        // Calculating the token reserves within liquidity pairs
        for (const pairAddress of this.pairs) {

          // Total reserves of tokens in the liquidity pool
          const reserve0 = parseFloat(ethers.utils.formatEther(pairs[pairAddress].pairReserves._reserve0))
          const reserve1 = parseFloat(ethers.utils.formatEther(pairs[pairAddress].pairReserves._reserve1))

          // Token prices
          const token0Price = this.priceModel.getPriceUsd(pairs[pairAddress].token0Address)
          const token1Price = this.priceModel.getPriceUsd(pairs[pairAddress].token1Address)

          // Percentage of LP tokens that are held by the vault
          const vaultTokenShare : number = toFloat(pairs[pairAddress].pairInVault) / toFloat(pairs[pairAddress].pairTotalSupply)

          // Number of individual tokens in the vault
          const token0InVault : number = reserve0 * vaultTokenShare
          const token1InVault : number = reserve1 * vaultTokenShare

          // USD value of tokens in the vault
          const token0USD = token0Price * token0InVault
          const token1USD = token1Price * token1InVault

          totalBackingUSD += token0USD
          totalBackingUSD += token1USD

          if (pairs[pairAddress].token0Name in vaultData) {
            vaultData[pairs[pairAddress].token0Name] += token0USD
          }
          else {
            vaultData[pairs[pairAddress].token0Name] = token0USD
          }

          if (pairs[pairAddress].token1Name in vaultData) {
            vaultData[pairs[pairAddress].token1Name] += token1USD
          }
          else {
            vaultData[pairs[pairAddress].token1Name] = token1USD
          }
        }

        // Balances of individual tokens
        // TODO: call everything simultaneously
        for (const token of this.vault.tokens) {
          const tokenContract = new ethers.Contract(token, ERC20_ABI, this.multicall)

          const tokenName = await tokenContract.name()
          const tokenBalance = await tokenContract.balanceOf(this.contractAddress)
          const tokenPrice = this.priceModel.getPriceUsd(token)

          const tokenUSD = tokenPrice * toFloat(tokenBalance)

          totalBackingUSD += tokenUSD

          if (tokenName in vaultData) {
            vaultData[tokenName] += tokenUSD
          }
          else {
            vaultData[tokenName] = tokenUSD
          }
        }

        this.totalBacking = totalBackingUSD

        const tokenColors : any = {
          "Toad Network": "#66b535",
          "Lily Pad": "#bfed79",
          "Glmr Pad": "#bfed79",
          "Movr Pad": "#bfed79",
          "PARADOX NFT BSC": "#3ebbc2",
          "Wrapped BNB": "#f2d513",
          "BUSD Token": "#d98116",
          "Binance-Peg BUSD Token": "#d98116",
          "Wrapped GLMR": "#9970BA",
          "Wrapped MOVR": "#9970BA",
          "USD Coin": "#6E94BA",
          "Catoshi": "#627fa3"
        }

        // Updating the pie chart
        let tokenLabels = []
        let tokenValues = []
        let labelColors = []
        let smallBalances : number = 0
        let total : number = 0
        for (const [key, value]  of Object.entries(vaultData)) {
          if (value as number >= 500) {
            tokenLabels.push(key)
            tokenValues.push(value)
            if (key in tokenColors) {
              labelColors.push(tokenColors[key])
            }
            else {
              labelColors.push('A0A0A0')
            }
          }
          else {
            smallBalances += value as number
          }
        }
        tokenLabels.push('Other')
        tokenValues.push(smallBalances)
        labelColors.push('#ac9cba')
        


        const backingChart : any = this.$refs!.holdings!
        backingChart.updateOptions({
          labels: tokenLabels,
          colors: labelColors
        })
        backingChart.updateSeries(tokenValues)

        this.getPadInfo()
        window.setInterval(() => this.getPadInfo(), 2000)

        let subgraphTokenIds = [
          'pad-token',
          'pad-token-movr',
          'pad-token-glmr'
        ]

        const burnStatsResponse = await axios.post('https://api.thegraph.com/subgraphs/name/toadguy/' + subgraphTokenIds[this.ecosystemId], {
            query: `
            {
              tokens(address:"` + this.padAddress + `") {
                id
                address
                totalBurned
                totalSupply
              }
            }
            `
        })
        const padStats = JSON.parse(burnStatsResponse.request.response).data.tokens

        this.padBurned = parseFloat(padStats[0].totalBurned)

        this.vaultProcessed = true

        if (!this.inflationCalculated) {
          this.calculateCircSupply()
        }
        return
      },
      async getPadInfo() {
        // Getting PAD stats
        const padContract = new ethers.Contract(this.padAddress, ERC20_ABI, this.multicall)
        this.padSupply = await padContract.totalSupply()
        this.padPrice = this.priceModel.getPriceUsd(this.padAddress)
        this.mcap = toFloat(this.padSupply as any) * this.padPrice


        const percentage = (this.totalBacking / this.mcap) * 100.0
        this.backingPercentage = percentage.toFixed(1)

        const allowance : ethers.BigNumber = await padContract.allowance(this.address, this.contractAddress)
        this.userTokenAllowance = toFloat(allowance)

        let userBalance = await padContract.balanceOf(this.address)
        this.userPadBalance = toFloat(userBalance)
        
      },
      calculateCircSupply() {
        const maxSupply = 200*1e9

        let circulating = 10*1e9
        let mintRate = 0.0013
        let remainingSupply = 190*1e9
        let launchDate = 1620490010

        if (this.ecosystemId != 0) {
          circulating = 20*1e9
          remainingSupply = 180*1e9
          mintRate = 0.0009
        }
        if (this.ecosystemId == 1) {
          launchDate = 1635753600
        }
        if (this.ecosystemId == 2) {
          launchDate = 1641888000
        }

        const circSupply = []
        const inflationRate = []
        const mintedPerDay = []
        const remainingSupplyPerDay = []
        const arr = []
        for(let i = 0; i<= 3650; i++) {
          const epoch = (launchDate + i*86400) *1000
          arr[i] = i
          const mint = mintRate*remainingSupply
          circulating = circulating + mint
          circSupply[i] = [epoch, this.round(circulating/1e9, 3)]
          mintedPerDay[i] = mint
          inflationRate[i] = [epoch, this.round(mint/circulating*100, 4)]
          remainingSupplyPerDay[i] = remainingSupply
          remainingSupply = remainingSupply - mint

          const inflationChart : any = this.$refs!.inflation!
          const supplyChart : any = this.$refs!.supply!

          if(i == 3650) {
            inflationChart.updateSeries([{
              data: inflationRate
            }]);
            inflationChart.zoomX(new Date(launchDate *1000).getTime(), new Date((launchDate + 2*365*86400) *1000).getTime())
            supplyChart.updateSeries([{
              data: circSupply
            }]);
            supplyChart.zoomX(new Date(launchDate *1000).getTime(), new Date((launchDate + 1*365*86400) *1000).getTime())
          }
        }
      },
      async approve() {
        let padContract = new ethers.Contract(this.padAddress, ERC20_ABI, this.multicall)
        padContract = padContract!.connect(this.web3!)
        let amount = ethers.utils.parseEther( this.amount.toString().replace(',','.') )
        const tx = await padContract.populateTransaction.approve(this.contractAddress, amount)
        await this.safeSendTransaction({ tx, targetChainId: this.chainId })
      },
      async redeem() {
        const vaultContract = new ethers.Contract(this.contractAddress, this.contractABI, this.multicall)
        let amount = ethers.utils.parseEther( this.amount.toString().replace(',','.') )
        const tx = await vaultContract.populateTransaction.redeemBacking(amount)
        const succeeded = await this.safeSendTransaction({ tx, targetChainId: this.chainId })
      },
      ...mapActions(['requestConnect', 'safeSendTransaction'])
    },
  })
